<template>
  <div>
    <v-card class="card-shadow">
      <div v-if="tableTitle" class="card-header-padding card-border-bottom">
        <div class="font-weight-600 text-h3 text-typo mb-1">
          {{ tableTitle }}
        </div>
        <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
          {{ tableSubTitle }}
        </p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="items"
          class="table thead-light"
          :server-items-length="paginationDetails.itemsLength"
          :loading="paginationDetails.loading"
          hide-default-footer
          :items-per-page="paginationDetails.itemsPerPage"
          mobile-breakpoint="0"
          :no-data-text="noDataText"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-if="showSearchBox"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    :placeholder="searchPlaceholder"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-btn
                v-if="showCreateNewItemButton"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ms-3"
                color="#5e72e4"
                @click="createNewItem"
                ><v-icon class="me-3">ni-fat-add</v-icon>
                {{ createNewItemLabel }}
              </v-btn>

              <v-dialog v-model="dialogUpdateStatus.visible" max-width="500px">
                <v-card class="card-shadow card-padding">
                  <v-card-title class="pt-0 text-h4 text-typo justify-center">{{
                    dialogUpdateStatus.text
                  }}</v-card-title>
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeUpdateStatus"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-ls btn-danger py-3 px-6 rounded-sm"
                      color="#f5365c"
                      >Cancel</v-btn
                    >

                    <v-btn
                      @click="updateItemStatusConfirm"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-ls btn-primary py-3 px-6 rounded-sm"
                      color="#5e72e4"
                      >Yes</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete.visible" max-width="500px">
                <v-card class="card-shadow card-padding">
                  <v-card-title class="pt-0 text-h4 text-typo justify-center">
                    Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeDeleteItem"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-ls btn-danger py-3 px-6 rounded-sm"
                      color="#f5365c"
                      >Cancel</v-btn
                    >

                    <v-btn
                      @click="deleteItemConfirm"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-ls btn-primary py-3 px-6 rounded-sm"
                      color="#5e72e4"
                      >Yes</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- <template v-slot:item.name="{ item }">
            <div class="d-flex align-center">
              <v-checkbox
                v-model="checkboxModel"
                :value="item"
                color="#5e72e4"
                :ripple="false"
                class="ma-0 checkbox-custom checkbox-thinner me-3 pt-0"
                hide-details
              ></v-checkbox>
              <span class="text-body text-h5">
                {{ item.name }}
              </span>
            </div>
          </template> -->

          <template v-slot:item.email="{ item }">
            <span class="text-body text-h5">
              {{ item.email }}
            </span>
          </template>

          <template v-slot:item.age="{ item }">
            <span class="text-body text-h5">
              {{ item.age }}
            </span>
          </template>

          <template v-slot:item.salary="{ item }">
            <span class="text-body text-h5">
              {{ item.salary }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="enabledActionEdit"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                  color="#40a0fb"
                >
                  <v-icon size="12">ni-palette</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="enabledActionDeactivate"
                  v-bind="attrs"
                  v-on="on"
                  @click="updateItemStatus(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                  :color="getDeactivateButtonColour(item)"
                >
                  <v-icon v-if="item.status == 'active'" size="12">
                    ni-button-pause
                  </v-icon>
                  <v-icon v-if="item.status != 'active'" size="12">
                    ni-button-play
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                item.status == "active" ? "Deactivate" : "Activate"
              }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="enabledActionDelete"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                  color="#f5365c"
                >
                  <v-icon size="12">ni-fat-remove</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="2" class="d-flex align-center">
            <span class="text-body me-3">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="newItemsPerPage"
              @input="updateItemsPerPage"
              placeholder="Items per page"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#5e72e4"
              v-model="currentPage"
              :total-visible="8"
              :length="paginationDetails.pageCount"
              circle
              @input="updateSelectedPage"
              @next="paginateFetchFromUrl('next')"
              @previous="paginateFetchFromUrl('previous')"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import debounce from "debounce";

export default {
  name: "paginated-tables",
  props: {
    createNewItemLabel: {
      default: "Create New Item",
      type: String,
      required: false,
    },
    enabledActionEdit: {
      default: true,
      type: Boolean,
      required: false,
    },
    enabledActionDeactivate: {
      default: true,
      type: Boolean,
      required: false,
    },
    enabledActionDelete: {
      default: false,
      type: Boolean,
      required: false,
    },
    showSearchBox: {
      default: false,
      type: Boolean,
      required: false,
    },
    searchPlaceholder: {
      default: "Search",
      type: String,
      required: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    noDataText: {
      default: "No data",
      type: String,
      required: false,
    },
    paginationDetails: {
      default: () => ({
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: null,
        lastPageUrl: null,
        nextPageUrl: null,
        previousPageUrl: null,
        loading: false,
      }),
      type: Object,
      required: false,
    },
    showCreateNewItemButton: {
      type: Boolean,
      default: true,
    },
    tableSubTitle: {
      default: "",
      type: String,
      required: false,
    },
    tableTitle: {
      default: "",
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dialogDelete: {
        item: {},
        visible: false,
      },
      dialogUpdateStatus: {
        item: {},
        status: "active",
        text: "",
        visible: false,
      },
      currentPage: 1,
      newItemsPerPage: 0,
      dialog: false,
      checkboxModel: [],
      search: "",
      editedIndex: -1,
      editedItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      defaultItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      debounceTimeout: {
        updateItemsPerPage: null,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDeleteItem() {
      this.dialogDelete.visible = false;
    },

    closeUpdateStatus() {
      this.dialogUpdateStatus.visible = false;
    },

    createNewItem() {
      this.$emit("createNewItem");
    },

    deleteItem(item) {
      this.dialogDelete.item = Object.assign({}, item);
      this.dialogDelete.visible = true;
    },

    deleteItemConfirm() {
      this.$emit("deleteItem", this.dialogDelete.item);
      this.dialogDelete.item = Object.assign({}, {});
      this.closeDeleteItem();
    },

    getDeactivateButtonColour(item) {
      if (item.status == "active") {
        return "#fb6340";
      }

      return "#2dce89";
    },

    updateItemStatus(item) {
      this.dialogUpdateStatus.item = Object.assign({}, item);

      if (["active"].includes(item.status)) {
        this.dialogUpdateStatus.status = "inactive";
        this.dialogUpdateStatus.text =
          "Are you sure you want to deactivate this item?";
      } else {
        this.dialogUpdateStatus.status = "active";
        this.dialogUpdateStatus.text =
          "Are you sure you want to activate this item?";
      }

      this.dialogUpdateStatus.visible = true;
    },

    updateItemStatusConfirm() {
      this.$emit(
        "updateItemStatus",
        this.dialogUpdateStatus.item,
        this.dialogUpdateStatus.status
      );
      this.dialogUpdateStatus.item = Object.assign({}, {});
      this.closeUpdateStatus();
    },

    editItem(item) {
      this.$emit("editItem", item);
    },

    emitUpdateSearchValue: debounce(function () {
      this.$emit("updateSearchValue", this.search);
    }, 500),

    paginateFetchFromUrl(direction) {
      if (direction === "next") {
        this.$emit("fetchNextPage");
      } else {
        this.$emit("fetchPreviousPage");
      }
    },

    updateItemsPerPage(newValue) {
      clearTimeout(this.debounceTimeout.updateItemsPerPage);

      this.debounceTimeout.updateItemsPerPage = setTimeout(() => {
        this.$emit("updateItemsPerPage", newValue);
      }, 1000);
    },

    updateSelectedPage(newValue) {
      this.$emit("updateSelectedPage", newValue);
    },
  },
  mounted() {
    this.newItemsPerPage = parseInt(this.paginationDetails.itemsPerPage);
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "paginationDetails.itemsPerPage"(val) {
      this.newItemsPerPage = parseInt(val);
    },
    "paginationDetails.page"(val) {
      this.currentPage = val;
    },
    search() {
      this.emitUpdateSearchValue();
    },
  },
};
</script>
